import {useRef} from 'react';
import {EnhancedMenuItem} from '~/lib/utils';
import {useHeaderScroll} from './useHeaderScroll';
import {Center} from '../Center';
import {Link, NavLink} from '@remix-run/react';

interface HeaderDesktopProps {
  menu: EnhancedMenuItem[];
  isMenuVisible: boolean;
  logoUrl?: string;
  isFixed?: boolean;
}

export function HeaderDesktop({
  menu,
  logoUrl,
  isMenuVisible,
  isFixed,
}: HeaderDesktopProps) {
  const ref = useRef<HTMLElement>(null);
  const className = useHeaderScroll({ref, isMenuVisible, isFixed});

  return (
    <header
      ref={ref}
      role="banner"
      className={`hidden xl:block h-24 z-40 top-0 w-full antialiased transition ${className}`}
    >
      <Center className="flex gap-8 items-center px-5 h-full sm:px-10 xl:px-20">
        <div className="flex-1">
          <Link to="/">
            <img
              width={190}
              height={36}
              src={logoUrl}
              alt="HPMLED"
              loading="eager"
            />
          </Link>
        </div>

        <nav>
          <ul className="flex gap-6">
            {menu.map((e: any) => {
              return (
                <li key={e.id}>
                  <NavLink
                    to={e.to}
                    target={e.target}
                    prefetch="intent"
                    className={({isActive}) =>
                      `uppercase font-semibold transition text-sm ${
                        isActive ? 'text-blue-400' : 'text-white'
                      }`
                    }
                  >
                    {e.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex-1" />
      </Center>
    </header>
  );
}
