import {Dialog, Transition} from '@headlessui/react';
import {Fragment, MutableRefObject} from 'react';

interface ModalCotizaTuServicioProps {
  onClose: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
  initialFocus?: MutableRefObject<any>;
}

export function Modal({
  onClose,
  isOpen,
  children,
  initialFocus,
}: ModalCotizaTuServicioProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={initialFocus}
        // open={isOpen}
        onClose={onClose}
        className="relative z-50"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0">
          <div className="flex justify-center items-center min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="relative py-24 px-5 w-full bg-white rounded-lg sm:px-10 lg:p-24 max-w-[1120px]">
                <div className="absolute top-5 right-5">
                  <button onClick={onClose}>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      className="fill-blue-dark"
                    >
                      <path
                        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                        fill="current"
                      />
                    </svg>
                  </button>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
