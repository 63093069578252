import {Trans, useTranslation} from 'react-i18next';
import {Center} from '~/components/Center';
import {LinkButton} from '~/components/LinkButton';
import {Paragraph} from '~/components/Paragraph';
import {Title} from '~/components/Title';
import {TitleSection} from '~/components/TitleSection';
import {
  ImageHeader,
  IMAGE_HEADER_HEIGHT,
  IMAGE_HEADER_WIDTH,
} from '~/features/shared';

export function NotFoundPage() {
  const {t} = useTranslation();

  const baseImageUrl =
    'https://cdn.shopify.com/s/files/1/0745/9438/6205/files/404-billboard-hpm.png?v=1684946785k';
  const imageUrl = `${baseImageUrl}&width=${IMAGE_HEADER_WIDTH}&height=${IMAGE_HEADER_HEIGHT}&crop=center`;

  return (
    <Center className="pb-20 w-full lg:px-20 lg:pt-10">
      <ImageHeader
        image={{
          mediaContentType: 'IMAGE',
          image: {
            url: imageUrl,
            altText: 'Página no encontrada',
          },
        }}
        breadcrumb={[
          {
            title: t('inicio'),
            to: '/',
          },
          {
            title: '404',
          },
        ]}
        title="404"
      />

      <section className="px-5 sm:px-10">
        <TitleSection i18nKey="pages:404:title.0" className="mb-4" />

        <Title
          i18nKey="pages:404:title.1"
          className="mb-6 text-2xl leading-normal sm:text-4xl"
        />

        <Paragraph i18nKey="pages:404:content.0" className="mb-6" />

        <Paragraph i18nKey="pages:404:content.1" className="mb-10" />

        <div className="flex">
          <LinkButton to="/">
            <Trans i18nKey="pages:404:action" />
          </LinkButton>
        </div>
      </section>
    </Center>
  );
}
