export function IconFacebook({className}: {className?: string}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <path
        d="M28 27H33L35 19H28V15C28 12.94 28 11 32 11H35V4.28C34.348 4.194 31.886 4 29.286 4C23.856 4 20 7.314 20 13.4V19H14V27H20V44H28V27Z"
        fill="white"
      />
    </svg>
  );
}
