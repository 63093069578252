import {useMemo, useRef} from 'react';
import {twMerge} from 'tailwind-merge';
import {FieldValues, useController, UseControllerProps} from 'react-hook-form';
import {Input} from './Input';
import Fuse from 'fuse.js';
import {useCombobox} from 'downshift';

export interface InputAutoCompleteProps<T extends FieldValues> {
  options: string[];
  controllerProps: UseControllerProps<T>;
  className?: string;
  id?: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  hint?: string;
}

export function InputAutoComplete<T extends FieldValues>({
  id,
  options,
  className,
  controllerProps,
  label,
  placeholder,
  disabled,
  hint,
}: InputAutoCompleteProps<T>) {
  const ref = useRef<HTMLInputElement | null>(null);

  const {
    field: {value, onChange, onBlur, ref: formRef, name},
    fieldState: {error},
  } = useController(controllerProps);

  const items = useMemo(
    () => {
      if (!value) {
        return options;
      }

      const fuse = new Fuse(options, {includeScore: true});

      const result = fuse.search(value.toString());

      return result.map((r) => r.item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options), value]
  );

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    onInputValueChange: ({inputValue}) => {
      onChange(inputValue);
    },
  });

  const {ref: comboboxRef, ...inputProps} = getInputProps();

  return (
    <div className={twMerge('relative', className)}>
      <Input
        ref={(e) => {
          formRef(e);
          comboboxRef(e);
          ref.current = e;
        }}
        onBlur={onBlur}
        name={name}
        id={id}
        label={label}
        placeholder={placeholder}
        labelProps={getLabelProps()}
        error={isOpen ? undefined : error}
        hint={hint}
        renderInputEnd={() => {
          return (
            <button
              aria-label="Abrir opciones"
              className={twMerge(
                'px-2 absolute right-0 inset-y-0',
                disabled && 'opacity-50 cursor-not-allowed'
              )}
              disabled={disabled}
              type="button"
              {...getToggleButtonProps()}
            >
              {isOpen && items.length ? <ChevronUp /> : <ChevronDown />}
            </button>
          );
        }}
        disabled={disabled}
        {...inputProps}
      />

      <ul
        className={`absolute z-50 bg-gray-100 rounded-xl inset-x-0 shadow-md  ${
          !(isOpen && options.length) && 'hidden'
        }`}
        style={{
          top: 'calc(100% + 0.5rem)',
        }}
        {...getMenuProps()}
      >
        {items.map((o, i) => {
          const isFirst = i === 0;
          const isLast = i === items.length - 1;

          return (
            <li
              key={i}
              className={twMerge(
                `px-8 py-2 font-medium w-full text-left transition cursor-pointer`,
                isFirst && 'rounded-t-xl',
                isLast && 'rounded-b-xl',
                // selectedItem === o && 'bg-blue-500 text-white',
                highlightedIndex === i && 'bg-gray-300 text-black'
              )}
              {...getItemProps({item: o, index: i})}
            >
              {o}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ChevronDown() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-blue-dark">
      <path
        d="M7.41 8.57999L12 13.17L16.59 8.57999L18 9.99999L12 16L6 9.99999L7.41 8.57999Z"
        fill="current"
      />
    </svg>
  );
}

function ChevronUp() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className="fill-blue-dark">
      <path
        d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z"
        fill="current"
      />
    </svg>
  );
}
