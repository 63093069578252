import {Dialog, Transition} from '@headlessui/react';
import {NavLink} from '@remix-run/react';
import {Fragment} from 'react';
import {EnhancedMenuItem} from '~/lib/utils';
import {IconClose} from '../IconClose';

interface MenuMobileProps {
  menu: EnhancedMenuItem[];
  isVisible: boolean;
  onClose: () => void;
}

export function MenuMobile({menu, isVisible, onClose}: MenuMobileProps) {
  return (
    <Transition as={Fragment} appear show={isVisible}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 left-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-300"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="bg-black h-screen w-screen max-w-lg">
              <div className="flex justify-end items-center h-[70px] p-5">
                <button onClick={onClose}>
                  <IconClose />
                </button>
              </div>

              <nav className="flex flex-col">
                <ul>
                  {menu.map((e: any) => {
                    return (
                      <li key={e.id}>
                        <NavLink
                          onClick={onClose}
                          to={e.to}
                          target={e.target}
                          prefetch="intent"
                          className={({isActive}) =>
                            `block px-5 sm:px-10 py-4 uppercase font-semibold transition text-sm ${
                              isActive ? 'text-blue-400' : 'text-white'
                            }`
                          }
                        >
                          {e.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
