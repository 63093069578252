export function IconInstagram({className}: {className?: string}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <path
        d="M33 4.5H15C12.2161 4.50298 9.54715 5.61018 7.57867 7.57867C5.61018 9.54715 4.50298 12.2161 4.5 15V33C4.50298 35.7839 5.61018 38.4528 7.57867 40.4213C9.54715 42.3898 12.2161 43.497 15 43.5H33C35.7839 43.497 38.4528 42.3898 40.4213 40.4213C42.3898 38.4528 43.497 35.7839 43.5 33V15C43.497 12.2161 42.3898 9.54715 40.4213 7.57867C38.4528 5.61018 35.7839 4.50298 33 4.5ZM24 33C22.22 33 20.4799 32.4722 18.9999 31.4832C17.5198 30.4943 16.3663 29.0887 15.6851 27.4442C15.0039 25.7996 14.8257 23.99 15.1729 22.2442C15.5202 20.4984 16.3774 18.8947 17.636 17.636C18.8947 16.3774 20.4984 15.5202 22.2442 15.1729C23.99 14.8257 25.7996 15.0039 27.4442 15.6851C29.0887 16.3663 30.4943 17.5198 31.4832 18.9999C32.4722 20.4799 33 22.22 33 24C32.9975 26.3862 32.0485 28.6739 30.3612 30.3612C28.6739 32.0485 26.3862 32.9975 24 33ZM35.25 15C34.805 15 34.37 14.868 34 14.6208C33.63 14.3736 33.3416 14.0222 33.1713 13.611C33.001 13.1999 32.9564 12.7475 33.0432 12.311C33.13 11.8746 33.3443 11.4737 33.659 11.159C33.9737 10.8443 34.3746 10.63 34.811 10.5432C35.2475 10.4564 35.6999 10.501 36.111 10.6713C36.5222 10.8416 36.8736 11.13 37.1208 11.5C37.368 11.87 37.5 12.305 37.5 12.75C37.5 13.3467 37.2629 13.919 36.841 14.341C36.419 14.7629 35.8467 15 35.25 15ZM30 24C30 25.1867 29.6481 26.3467 28.9888 27.3334C28.3295 28.3201 27.3925 29.0892 26.2961 29.5433C25.1997 29.9974 23.9933 30.1162 22.8295 29.8847C21.6656 29.6532 20.5965 29.0818 19.7574 28.2426C18.9182 27.4035 18.3468 26.3344 18.1153 25.1705C17.8838 24.0067 18.0026 22.8003 18.4567 21.7039C18.9108 20.6075 19.6799 19.6705 20.6666 19.0112C21.6533 18.3519 22.8133 18 24 18C25.5913 18 27.1174 18.6321 28.2426 19.7574C29.3679 20.8826 30 22.4087 30 24Z"
        fill="white"
      />
    </svg>
  );
}
