export function IconTikTok({className}: {className?: string}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
    >
      <path
        d="M33.2 11.64C31.8329 10.0792 31.0794 8.07486 31.08 6H24.9V30.8C24.8523 32.142 24.2857 33.4132 23.3195 34.3459C22.3533 35.2785 21.0629 35.7998 19.72 35.8C16.88 35.8 14.52 33.48 14.52 30.6C14.52 27.16 17.84 24.58 21.26 25.64V19.32C14.36 18.4 8.32001 23.76 8.32001 30.6C8.32001 37.26 13.84 42 19.7 42C25.98 42 31.08 36.9 31.08 30.6V18.02C33.586 19.8197 36.5947 20.7853 39.68 20.78V14.6C39.68 14.6 35.92 14.78 33.2 11.64Z"
        fill="white"
      />
    </svg>
  );
}
