import {useState} from 'react';
import {EnhancedMenuItem} from '~/lib/utils';
import {Footer} from './Footer';
import {HeaderDesktop} from './HeaderDesktop';
import {HeaderMobile} from './HeaderMobile';
import {MenuMobile} from './MenuMobile';

interface LayoutProps {
  children?: React.ReactNode;
  headerMenuItems: EnhancedMenuItem[];
  logoUrl?: string;
  isFixedHeader?: boolean;
}

export function Layout({
  children,
  headerMenuItems,
  isFixedHeader,
  ...props
}: LayoutProps) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const menuItems = headerMenuItems.filter((e) => !e.to.includes('proyectos'));

  return (
    <div className="flex flex-col min-h-screen antialiased min-w-[320px]">
      <HeaderDesktop
        isMenuVisible={isMenuVisible}
        menu={menuItems}
        isFixed={isFixedHeader}
        {...props}
      />

      <HeaderMobile
        isMenuVisible={isMenuVisible}
        onOpen={() => setIsMenuVisible(true)}
        isFixed={isFixedHeader}
        {...props}
      />
      <MenuMobile
        menu={menuItems}
        isVisible={isMenuVisible}
        onClose={() => setIsMenuVisible(false)}
      />

      <main className="flex flex-col flex-1">{children}</main>

      <Footer {...props} menu={menuItems} />
    </div>
  );
}
