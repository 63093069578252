import {Localizations} from '~/lib/type';

export const countries: Localizations = {
  default: {
    label: 'Mexico (MXN $)',
    language: 'ES',
    country: 'MX',
    currency: 'MXN',
  },
  EN: {
    label: 'United States (USD $)',
    language: 'EN',
    country: 'US',
    currency: 'USD',
  },
};
