import {useLocation} from '@remix-run/react';
import {RefObject, useEffect, useState} from 'react';

export function useHeaderScroll({
  ref,
  isMenuVisible,
  isFixed: isFixedProp,
}: {
  ref: RefObject<HTMLElement>;
  isMenuVisible: boolean;
  isFixed?: boolean;
}) {
  const {pathname} = useLocation();

  // HACK: find a better way to do this!!
  const isHomeOrAdvertisingPage = !pathname.match(
    /\/(nosotros|productos|servicios|proyectos|contacto|blogs|gracias)\/?/
  );
  const [isTransparent, setIsTransparent] = useState(isHomeOrAdvertisingPage);

  // fixed: is on top of content.
  const isFixed =
    isFixedProp !== undefined ? isFixedProp : isHomeOrAdvertisingPage;

  useEffect(() => {
    const changeBackground = () => {
      if (isMenuVisible) {
        return;
      }
      const isTransparent = ref.current
        ? ref.current.clientHeight >= window.scrollY
        : false;

      setIsTransparent(isFixed ? isTransparent : false);
    };

    changeBackground();

    window.addEventListener('scroll', changeBackground);

    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [isMenuVisible, ref, isFixed]);

  const classNames: string[] = [
    isFixed ? 'fixed' : 'sticky',
    isTransparent ? 'bg-transparent' : 'bg-black',
  ];

  return classNames.join(' ');
}
