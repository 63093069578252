import {useTranslation} from 'react-i18next';

export function GenericError({
  error,
}: {
  error?: {message: string; stack?: string};
}) {
  const {t} = useTranslation();

  let message = 'Unknown error';

  // TODO hide error in prod?
  if (error) {
    message = `${error.message}`;
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return (
    <>
      {error?.stack && (
        <pre
          style={{
            padding: '2rem',
            background: 'hsla(10, 50%, 50%, 0.1)',
            color: 'red',
            overflow: 'auto',
            maxWidth: '100%',
            marginBottom: '2rem',
            borderRadius: '0.5rem',
          }}
          dangerouslySetInnerHTML={{
            __html: addLinksToStackTrace(error.stack),
          }}
        />
      )}
    </>
  );
}

function addLinksToStackTrace(stackTrace: string) {
  return stackTrace?.replace(
    // eslint-disable-next-line no-useless-escape
    /^\s*at\s?.*?[(\s]((\/|\w\:).+)\)\n/gim,
    (all, m1) =>
      all.replace(
        m1,
        `<a href="vscode://file${m1}" class="hover:underline">${m1}</a>`
      )
  );
}
