import {
  AppLoadContext,
  type LinksFunction,
  type LoaderArgs,
} from '@shopify/remix-oxygen';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from '@remix-run/react';
import invariant from 'tiny-invariant';
import type {Cart, Shop} from '@shopify/hydrogen/storefront-api-types';
//@ts-ignore
import styles from './styles/app.css';
//@ts-ignore
import tailwind from './styles/tailwind.css';
import {Layout} from './components/Layout';
import {useTranslation} from 'react-i18next';
import {useChangeLanguage} from 'remix-i18next';
import {DEFAULT_LOCALE, EnhancedMenu, parseMenu} from './lib/utils';
import {NotFoundPage} from './pages/NotFoundPage';
import {ErrorPage} from './pages/ErrorPage';
import {ModalCotizaTuServicio} from './components/ModalCotizaTuServicio';
import {ModalCotizaTuProyecto} from './components/ModalCotizaTuProyecto';
import i18next from './i18next.server';
import {useEffect, useMemo} from 'react';

import * as gtag from '~/utils/gtags.client';

export interface LayoutData {
  headerMenu?: EnhancedMenu;
  footerMenu?: EnhancedMenu;
  cart?: Promise<Cart>;
  shop: Shop;
  aplicaciones: null | {
    field: {
      value: string;
    };
  };
  servicios: null | {
    field: {
      value: string;
    };
  };
}

async function getLayoutData({
  storefront,
}: AppLoadContext): Promise<LayoutData> {
  const data = await storefront.query<LayoutData>(LAYOUT_QUERY, {
    variables: {
      language: storefront.i18n.language,
    },
  });

  invariant(data, 'No data returned from Shopify API');

  /*
    Modify specific links/routes (optional)
    @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
    e.g here we map:
      - /blogs/news -> /news
      - /blog/news/blog-post -> /news/blog-post
      - /collections/all -> /products
  */
  const customPrefixes = {BLOG: '', CATALOG: 'products'};

  const headerMenu = data?.headerMenu
    ? parseMenu(data.headerMenu, customPrefixes)
    : undefined;

  const footerMenu = data?.footerMenu
    ? parseMenu(data.footerMenu, customPrefixes)
    : undefined;

  return {
    shop: data.shop,
    headerMenu,
    footerMenu,
    servicios: data.servicios,
    aplicaciones: data.aplicaciones,
  };
}

// wink wink
const DEV_TAG = 'G-GRBKLM7GWB';

export const links: LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: tailwind},
    {rel: 'stylesheet', href: styles},
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#303030',
    },
  ];
};

export async function loader({context, request}: LoaderArgs) {
  const t = await i18next.getFixedT(request);
  const layout = await getLayoutData(context);

  const aplicaciones: string[] = layout.aplicaciones
    ? JSON.parse(layout.aplicaciones.field.value)
    : [];

  const servicios: string[] = layout.servicios
    ? JSON.parse(layout.servicios.field.value)
    : [];

  const url = new URL(request.url).origin;

  return {
    layout,
    locale: context.storefront.i18n,
    aplicaciones: aplicaciones.map((a) => t(a)),
    servicios: servicios.map((s) => t(s)),
    gaTrackingId: ['G-JE4SCVP9QH', 'AW-11318780042', DEV_TAG],
    isDevelopment: url.includes('vercel'),
  };
}

export default function App() {
  const {layout, locale, aplicaciones, servicios, gaTrackingId, isDevelopment} =
    useLoaderData<typeof loader>();

  const {i18n} = useTranslation();

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale.language);

  const location = useLocation();

  useEffect(() => {
    gaTrackingId.forEach((id) => {
      gtag.pageview(location.pathname, id);
    });
  }, [location, gaTrackingId]);

  return (
    <html lang={locale.language} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </head>
      <body className="bg-white">
        <GTagTrackingIds ids={gaTrackingId} isDevelopment={isDevelopment} />
        <Layout
          headerMenuItems={layout.headerMenu?.items ?? []}
          logoUrl={layout.shop.brand?.logo?.image?.url}
        >
          <Outlet />
        </Layout>
        <ScrollRestoration />
        <Scripts />
        <ModalCotizaTuServicio servicios={servicios} />
        <ModalCotizaTuProyecto aplicaciones={aplicaciones} />
      </body>
    </html>
  );
}

function GTagTrackingIds({
  ids,
  isDevelopment,
}: {
  ids: string[];
  isDevelopment?: boolean;
}) {
  const isDev =
    process.env.NODE_ENV === 'development' || isDevelopment || !ids.length;

  useEffect(() => {
    if (isDev) {
      return;
    }

    const config: string[] = [];
    ids.forEach((id) => {
      config.push(`gtag('config', '${id}');`);
    });

    const s = document.createElement('script');
    s.id = 'gtag-init';
    s.async = true;
    s.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  ${config.join('\n')}
`;

    document.head.appendChild(s);

    return () => {
      document.head.removeChild(s);
    };
  }, [ids, isDev]);

  if (isDev) {
    return null;
  }

  return (
    <>
      {ids.map((id) => {
        return (
          <script
            key={id}
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
          />
        );
      })}
    </>
  );
}

export function ErrorBoundary() {
  const [root] = useMatches();

  const isDevelopment = root?.data?.isDevelopment ?? true;
  const gaTrackingId = root?.data?.gaTrackingId ?? [];
  const locale = root?.data?.locale ?? DEFAULT_LOCALE;
  const layout = root?.data?.layout;

  const routeError = useRouteError();
  const isRouteError = isRouteErrorResponse(routeError);

  const {t} = useTranslation();
  let title = t('pages:error:meta.title') ?? 'Error';

  if (isRouteError) {
    title = t('pages:404:meta.title') ?? '404';
  }

  return (
    <html lang={locale.language}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>{title}</title>
        <Links />
      </head>
      <body>
        <GTagTrackingIds ids={gaTrackingId} isDevelopment={isDevelopment} />
        <Layout
          headerMenuItems={layout?.headerMenu?.items ?? []}
          logoUrl={layout?.shop?.brand?.logo?.image?.url}
          isFixedHeader={false}
        >
          {isRouteError ? (
            <>
              {routeError.status === 404 ? (
                <NotFoundPage />
              ) : (
                <ErrorPage
                  error={{message: `${routeError.status} ${routeError.data}`}}
                />
              )}
            </>
          ) : (
            <ErrorPage
              error={routeError instanceof Error ? routeError : undefined}
            />
          )}
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

const LAYOUT_QUERY = `#graphql
  query layoutMenus(
    $language: LanguageCode
  ) @inContext(language: $language) {
    shop {
      id
      name
      description
      primaryDomain {
        url
      }
      brand {
       logo {
         image {
          url(transform: {
            maxWidth: 190,
            maxHeight: 36,
            preferredContentType:PNG
          })
         }
       }
     }
    }
    headerMenu: menu(handle: "main-menu") {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
    footerMenu: menu(handle: "footer") {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
    aplicaciones: metaobject(
        handle: {handle: "opciones-de-aplicaciones", type: "opciones_de_autocompletado"}
    ) {
        field(key: "opciones") {
            value
        }
    }
    servicios: metaobject(
        handle: {handle: "opciones-de-servicios", type: "opciones_de_autocompletado"}
    ) {
        field(key: "opciones") {
            value
        }
    }
  }
  fragment MenuItem on MenuItem {
    id
    resourceId
    tags
    title
    type
    url
  }
`;
