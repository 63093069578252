import {Trans} from 'react-i18next';
import {Center} from '~/components/Center';
import {GenericError} from '~/components/GenericError';
import {LinkButton} from '~/components/LinkButton';
import {Title} from '~/components/Title';

export function ErrorPage({
  error,
}: {
  error?: {message: string; stack?: string};
}) {
  return (
    <Center className="pt-10 pb-20 lg:px-20 px-5 sm:px-10 w-full">
      <Title
        i18nKey="pages:error:title"
        className="text-2xl leading-normal mb-6"
      />

      <GenericError error={error} />

      <div className="flex">
        <LinkButton to="/">
          <Trans i18nKey="pages:error:action" />
        </LinkButton>
      </div>
    </Center>
  );
}
