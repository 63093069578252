/* eslint-disable @typescript-eslint/naming-convention */
import {useLocation, useNavigate, useSearchParams} from '@remix-run/react';
import {MutableRefObject, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Button} from './Button';
import {Input} from './Input';
import {InputAutoComplete} from './InputAutoComplete';
import {Modal} from './Modal';
import {TextArea} from './TextArea';
import {Trans} from './Trans';

type FormValues = {
  name: string;
  'last-name': string;
  email: string;
  phone: string;
  service: string;
  message: string;
};

export function ModalCotizaTuServicio({servicios}: {servicios: string[]}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isOpen = !!searchParams.get('cotiza-tu-servicio');
  const onClose = () => {
    setSearchParams(
      (prev) => {
        const next = new URLSearchParams(prev);
        next.delete('cotiza-tu-servicio');
        return next;
      },
      {preventScrollReset: true}
    );
  };

  const initialFocusRef = useRef<any>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocus={initialFocusRef}>
      <h1 className="mb-12 text-4xl font-light text-center uppercase text-blue-dark">
        <Trans i18nKey="modals:cotiza-tu-servicio:title" />
      </h1>

      <Form initialFocusRef={initialFocusRef} servicios={servicios} />
    </Modal>
  );
}

function Form({
  initialFocusRef,
  servicios,
}: {
  initialFocusRef: MutableRefObject<any>;
  servicios: string[];
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    control,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      'last-name': '',
      email: '',
      phone: '',
      service: '',
      message: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (data) {
      reset();
    }
  }, [data, reset]);

  const onSubmit = handleSubmit(async (values) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    try {
      const res = await fetch('/api/enviar-cotiza-tu-servicio', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.status !== 200) {
        setError(new Error('Ha ocurrido un error al enviar el formulario.'));
      } else {
        navigate('/gracias', {
          state: {
            from: location.pathname,
          },
        });
        const data = await res.json();
        setData(data);
      }
    } catch (e) {
      setError(e as Error);
    }

    setIsLoading(false);
  });

  const {ref: nameInputRef, ...nameInputProps} = register('name', {
    required: 'El nombre es requerido.',
  });

  return (
    <form method="post" onSubmit={onSubmit} id="cotiza-tu-servicio-form">
      <div className="grid gap-8 mb-8 lg:grid-cols-2">
        <Input
          label={t('nombre') as string}
          placeholder={t('nombre') as string}
          error={errors.name}
          id="cotiza-name"
          {...nameInputProps}
          ref={(e) => {
            nameInputRef(e);
            initialFocusRef.current = e;
          }}
          disabled={isLoading}
        />

        <Input
          label={t('apellido') as string}
          error={errors['last-name']}
          placeholder={t('apellido') as string}
          id="cotiza-lastName"
          {...register('last-name', {
            required: 'El apellido es requerido.',
          })}
          disabled={isLoading}
        />
        <Input
          label={t('correo-electronico') as string}
          error={errors.email}
          placeholder={t('correo-electronico') as string}
          id="cotiza-email"
          type="email"
          {...register('email', {
            required: 'El correo electrónico es requerido.',
          })}
          disabled={isLoading}
        />
        <Input
          label={t('telefono') as string}
          error={errors.phone}
          placeholder={t('telefono') as string}
          id="cotiza-phone"
          {...register('phone', {
            required: 'El Teléfono es requerido.',
          })}
          disabled={isLoading}
        />
      </div>

      <InputAutoComplete
        controllerProps={{
          control,
          name: 'service',
          rules: {
            required: 'El servicios es requerido.',
          },
        }}
        label={t('servicio')}
        hint="Elige un servicio de la lista o escríbelo si no se encuentra."
        placeholder={t('servicio') as string}
        id="cotiza-service"
        className="mb-8"
        disabled={isLoading}
        options={servicios}
      />

      <TextArea
        label={t('modals:cotiza-tu-servicio:form.message.label')}
        placeholder={
          t('modals:cotiza-tu-servicio:form.message.placeholder') as string
        }
        error={errors.message}
        id="cotiza-message"
        className="mb-8"
        {...register('message', {
          required: 'El mensaje es requerido.',
        })}
        disabled={isLoading}
      />

      {error ? (
        <p className="mb-8 font-medium text-red-600">{error.message}</p>
      ) : null}

      {data ? (
        <p className="mb-8 font-medium text-green-600">
          ¡Gracias por comunicarte con nosotros! Tu mensaje ha sido recibido y
          nos pondremos en contacto contigo tan pronto como sea posible.
        </p>
      ) : null}

      <Button
        className="w-full max-w-[200px]"
        type="submit"
        isLoading={isLoading}
      >
        {t('enviar')}
      </Button>
    </form>
  );
}
