import {Trans} from '../Trans';
import {EnhancedMenuItem} from '~/lib/utils';
import {Center} from '../Center';
import {Link, NavLink} from '@remix-run/react';
import {IconFacebook} from '../IconFacebook';
import {IconInstagram} from '../IconInstagram';
import {IconLinkedIn} from '../IconLinkedIn';
import {IconTikTok} from '../IconTikTok';

interface FooterProps {
  menu: EnhancedMenuItem[];
  logoUrl?: string;
}

type SocialType = {
  alt: string;
  to: string;
  icon: React.ReactNode;
};

const socials: SocialType[] = [
  {
    alt: 'HPMLED Facebook',
    to: 'https://www.facebook.com/ledhpm',
    icon: <IconFacebook />,
  },
  {
    alt: 'HPMLED Instagram',
    to: 'https://www.instagram.com/hpmled',
    icon: <IconInstagram />,
  },
  {
    alt: 'HPMLED TikTok',
    to: 'https://www.tiktok.com/@hpmledgrupovision',
    icon: <IconTikTok />,
  },
  {
    alt: 'HPMLED LinkedIn',
    to: 'https://www.linkedin.com/company/grupovisionmx',
    icon: <IconLinkedIn />,
  },
];

export function Footer({logoUrl, menu}: FooterProps) {
  const now = new Date();

  return (
    <footer className="bg-black">
      <Center className="py-10 px-5 sm:px-10 xl:px-20">
        <section className="flex flex-col flex-wrap gap-5 mb-10 lg:flex-row">
          <div className="flex flex-col flex-1 gap-5 items-start">
            <div className="shrink-0">
              <Link to="/">
                <img width={190} height={36} src={logoUrl} alt="HPMLED" />
              </Link>
            </div>

            <h1 className="text-xs font-medium text-white">
              <Trans i18nKey="footer:title" />
            </h1>

            <nav>
              <ul className="flex flex-wrap gap-5">
                {menu.map((e: any) => {
                  return (
                    <li key={e.id}>
                      <NavLink
                        to={e.to}
                        target={e.target}
                        prefetch="intent"
                        className={({isActive}) =>
                          `font-medium transition text-xs ${
                            isActive ? 'text-blue-400' : 'text-white'
                          }`
                        }
                      >
                        {e.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* <aside>
            <h1 className="mb-5 text-xs font-medium leading-relaxed text-white lg:text-right lg:max-w-[350px]">
              <Trans i18nKey="footer.action" />
            </h1>

            <div className="flex flex-col flex-wrap gap-2 sm:flex-row">
              <input
                className="flex-1 py-4 px-6 text-xs bg-white rounded-full"
                type="email"
                id="email"
                name="email"
                placeholder={t('email') ?? 'Email'}
                aria-label={t('email') ?? 'Email'}
                autoComplete="email"
                required
              />

              <Button className="text-xs font-medium">
                <Trans i18nKey="suscribete" />
              </Button>
            </div>
          </aside> */}
        </section>

        <hr className="w-full h-px bg-white opacity-50" />

        <section className="flex flex-col-reverse gap-4 justify-between items-center mt-10 sm:flex-row">
          <p className="text-xs font-medium text-white">
            &copy; {`${now.getFullYear()}`} HPMLED. All rights reserved.
          </p>

          <div className="flex gap-4">
            {socials.map((s) => {
              return (
                <Link key={s.to} to={s.to} target="_blank" aria-label={s.alt}>
                  {s.icon}
                </Link>
              );
            })}
          </div>
        </section>
      </Center>
    </footer>
  );
}
