import {useRef} from 'react';
import {useHeaderScroll} from './useHeaderScroll';
import {IconMenu} from '../IconMenu';
import {Link} from '@remix-run/react';

interface HeaderMobileProps {
  logoUrl?: string;
  onOpen: () => void;
  isMenuVisible: boolean;
  isFixed?: boolean;
}

export function HeaderMobile({
  logoUrl,
  onOpen,
  isMenuVisible,
  isFixed,
}: HeaderMobileProps) {
  const ref = useRef<HTMLElement>(null);
  const className = useHeaderScroll({ref, isMenuVisible, isFixed});

  return (
    <header
      ref={ref}
      role="banner"
      className={`block xl:hidden h-[70px] z-40 top-0 w-full antialiased transition ${className}`}
    >
      <div className="flex gap-8 justify-between items-center px-5 mx-auto h-full sm:px-10 xl:px-20 max-w-[1280px]">
        <button className="xl:hidden" onClick={onOpen}>
          <IconMenu />
        </button>

        <div>
          <Link to="/">
            <img
              width={140}
              height={26}
              src={logoUrl}
              alt="HPMLED"
              loading="eager"
            />
          </Link>
        </div>
        <div />
      </div>
    </header>
  );
}
